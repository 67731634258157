import React from 'react';
import App from '../components/App';
import Header from '../components/Header';
import { headData } from '../data/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default () => {
  const { title, lang, description, keyword, siteUrl, ogpUrl } = headData;

  return (
    <>
      <Header
        title={title}
        lang={lang}
        description={description}
        keyword={keyword}
        baseUrl={siteUrl}
        siteUrl={siteUrl}
        ogpUrl={ogpUrl}
      />
      <App />
    </>
  );
};
